import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import './Terminal.css'; // Ensure this path is correct
import { useCommandHandler } from './useCommandHandler'; // Adjust according to your file structure
import { useAuth } from './useAuth'; // Adjust according to your file structure
import useStore from '../store/store'; // Adjust according to your file structure

const Terminal = () => {
    const [input, setInput] = useState('');
    const { user, signInWithGithub } = useAuth();
    const { history, executeCommand } = useCommandHandler();
    const inputRef = useRef(null);
    const historyRef = useRef(null);
    const apiResponse = useStore((state) => state.apiResponse);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        historyRef.current?.scrollTo(0, historyRef.current.scrollHeight);
    }, [history, apiResponse]);

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' && input.trim()) {
            if (input.trim().toLowerCase() === 'login github') {
                await signInWithGithub();
            } else {
                executeCommand(input.trim());
            }
            setInput('');
        }
    };

    const renderHistoryItem = (item, index) => {
        return (
            <ReactMarkdown key={index} remarkPlugins={[gfm]} className="markdown-content">
                {item.content}
            </ReactMarkdown>
        );
    };

    return (
        <div className="terminal">
            <div className="history" ref={historyRef}>
                {history.map(renderHistoryItem)}
                {apiResponse && (
                    <ReactMarkdown remarkPlugins={[gfm]} className="markdown-content">
                        {apiResponse}
                    </ReactMarkdown>
                )}
            </div>
            <div className="input-line">
                <span className="prompt">{user ? user.displayName.split(' ')[0].toLowerCase() : 'guest'}&gt;</span>
                <input
                    ref={inputRef}
                    type="text"
                    className="terminal-input"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    autoFocus
                />
            </div>
        </div>
    );
};

export default Terminal;
