import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Ensure this CSS file sets up the base styling for your app
import App from './App'; // Import the App component correctly
import reportWebVitals from './reportWebVitals';
import './firebase-config'; // Import Firebase configuration

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Optional: Setup for measuring app performance
reportWebVitals();
