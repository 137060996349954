// src/store/store.js
import { create } from 'zustand';

const useStore = create((set) => ({
  apiResponse: "",
  setApiResponse: (rawResponse) => {
    // Initialize a default message in case the response is empty or undefined
    let formattedResponse = "No response or undefined response.";

    // Check if rawResponse is an object and has the 'message' property
    if (typeof rawResponse === 'object' && rawResponse !== null) {
      // If rawResponse.message is an object itself, stringify it
      if (typeof rawResponse.message === 'object' && rawResponse.message !== null) {
        formattedResponse = JSON.stringify(rawResponse.message, null, 2);
      } else if (typeof rawResponse.message === 'string') {
        // If it's a string, use it directly
        formattedResponse = rawResponse.message;
      }
    } else if (typeof rawResponse === 'string') {
      // If rawResponse is a simple string
      formattedResponse = rawResponse;
    }
    // Update the Zustand store with the formatted response
    set({ apiResponse: formattedResponse });
  },
}));

export default useStore;
