import { useState, useEffect } from 'react';
import { auth, db } from '../firebase-config'; // Adjust this path as per your project structure
import { signInWithPopup, GithubAuthProvider, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';

export const useAuth = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const signInWithGithub = async () => {
        setLoading(true);
        const provider = new GithubAuthProvider();
        provider.addScope('repo'); // Customize based on needed scopes
        try {
            const result = await signInWithPopup(auth, provider);
            const { user } = result;
            const credential = GithubAuthProvider.credentialFromResult(result);
            const token = credential.accessToken; // Capture the GitHub token

            const userRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(userRef);
            if (!docSnap.exists()) {
                // If it's a new user, set up their document with basic info and GitHub token
                await setDoc(userRef, {
                    displayName: user.displayName,
                    email: user.email,
                    photoURL: user.photoURL,
                    githubToken: token, // Securely store the GitHub token
                });
            } else {
                // For existing users, just update the GitHub token
                await updateDoc(userRef, { githubToken: token });
            }

            setUser(user);
            setError(null);
        } catch (authError) {
            console.error("GitHub login failed:", authError);
            setError(authError.message);
        } finally {
            setLoading(false);
        }
    };

    const signOutUser = async () => {
        setLoading(true);
        try {
            await signOut(auth);
            setUser(null);
            setError(null);
        } catch (signOutError) {
            console.error("Error signing out:", signOutError);
            setError(signOutError.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });
        return unsubscribe; // Call unsubscribe on cleanup
    }, []);

    return { user, signInWithGithub, signOutUser, loading, error };
};
