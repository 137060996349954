import React from 'react';
import Terminal from './components/Terminal'; // Ensure the path matches the location of your Terminal component
import './App.css'; // Your main App CSS

function App() {
  return (
    <div className="App">
      <Terminal />
    </div>
  );
}

export default App;
