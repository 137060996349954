import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your sacred Firebase configuration, handed down through the environment variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID // This line is optional; include it if you're using Firebase Analytics
};

// Initialize the Firebase app once and only once, as decreed by the coding gods
const app = initializeApp(firebaseConfig);

// Bestow upon your app the powers of Firebase Authentication
export const auth = getAuth(app);

// Grant access to the mystical realms of Cloud Firestore
export const db = getFirestore(app);

// Unlock the celestial vaults of Firebase Storage
export const storage = getStorage(app);
