import { useState, useCallback } from 'react';
import axios from 'axios';
import { auth, db } from '../firebase-config'; // Ensure these imports match your project structure
import { doc, updateDoc, arrayUnion } from 'firebase/firestore'; // Import Firestore functions for updating documents

export const useCommandHandler = () => {
    const [history, setHistory] = useState([{ type: 'response', content: 'Welcome to SmartMerge Terminal. Type "help" for a list of commands.' }]);

    const addToHistory = useCallback((message, type = 'response') => {
        setHistory((prev) => [...prev, { type, content: message }]);
    }, []);

    const executeCommand = useCallback(async (commandInput) => {
        const spaceIndex = commandInput.indexOf(' ');
        let command, argsString;

        if (spaceIndex !== -1) {
            command = commandInput.substring(0, spaceIndex);
            argsString = commandInput.substring(spaceIndex + 1);
        } else {
            command = commandInput;
            argsString = ""; // No arguments
        }

        addToHistory(`> ${commandInput}`, 'command');

        switch (command.toLowerCase()) {
            case 'login':
                // Bypass the 'login' command here as it's handled in Terminal.js
                break;
            case 'connect':
                if (argsString.toLowerCase() === 'wallet') {
                    if (window.ethereum) {
                        try {
                            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                            addToHistory(`Wallets connected: ${accounts.join(', ')}`, 'response');

                            // Store connected wallets in Firestore if the user is authenticated
                            if (auth.currentUser) {
                                const userWalletsRef = doc(db, 'users', auth.currentUser.uid);
                                await updateDoc(userWalletsRef, {
                                    wallets: arrayUnion(...accounts) // Adds new wallets without removing existing ones
                                });
                                addToHistory("Wallet addresses have been stored successfully.", 'response');
                            } else {
                                addToHistory("User is not authenticated.", 'error');
                            }
                        } catch (error) {
                            addToHistory(`Error connecting to wallet: ${error.message}`, 'error');
                        }
                    } else {
                        addToHistory("MetaMask is not installed. Please install MetaMask to connect your wallet.", 'error');
                    }
                }
                break;
            default:
                // Handle other commands through the API call
                if (auth.currentUser) {
                    try {
                        const idToken = await auth.currentUser.getIdToken(true);
                        const response = await axios.post(
                            'https://us-central1-smartmerge.cloudfunctions.net/api', // Placeholder: Replace with your actual endpoint
                            { command, args: argsString ? [argsString] : [] },
                            { headers: { Authorization: `Bearer ${idToken}` } }
                        );
                        addToHistory(response.data.message || "Command executed successfully.", 'response');
                    } catch (error) {
                        const errorMessage = error.response ? (error.response.data.error || error.response.data) : error.message;
                        addToHistory(`Error executing command: ${errorMessage}`, 'error');
                    }
                } else {
                    addToHistory("Error: User not authenticated. Please log in.", 'error');
                }
                break;
        }
    }, [addToHistory]);

    return { history, executeCommand };
};
